.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.editDelete i {
  margin: 5px

}


.margin-middle {
  margin-right: auto;
  margin-left: auto;
}
.amount {
  display: inline-block;
  vertical-align: top;
  
}
.amount_currency {
  font-size: smaller;
  vertical-align: top;
  color: gray;
  margin-right: 3px;
  margin-top: 9px;
  display: inline-block;
}
.amount_amount {
  font-size: 2em;
  vertical-align: top;
  display: inline-block;
}
.amount_cents {
  font-size: smaller;
  vertical-align: top;
  margin-top: 9px;
  display: inline-block;
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}	
}
.expandable {
  animation: slideDown .5s ease-in-out;
}

/* Transaction In app Settlements */
.settlement-button {
	margin-left:2.5px;
	margin-right:2.5px;
}

/* Dialog */
.dialog {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
	z-index: 999;
	animation: slideDown .5s ease-in-out;
	overflow: auto;
	display: flex;
  justify-content: center;
  align-items: center;
}

.dialog > div {
	position: relative;
	margin: 10% auto;
	background-color: #ffffff;
	min-width: 30%;
	max-width: 90%;
	max-height: 95%;
	display: inline-block;
	
}

.hoverable {
	cursor: pointer;
}

.right-align-items { 
  display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.centered {
	width: 100px;
	height: 100px;

	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;
}

[data-letters] {
  content:attr(data-letters);
  display:inline-block;
  text-align:center;
  border-radius:50%;
  background:plum;
  vertical-align:middle;
  color:white;
	}
	
	.md-avatar-img {
		font-size:1em;
		width:2.5em;
		height:2.5em;
		line-height:2.5em;
		margin-right:1em;
	}

	.sm-avatar-img {
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1.5rem;
		font-size: 0.75rem;
		margin-right:0.2em;
	}